body {
        /* font-family: 'Open Sans', sans-serif; */
        background-color: black;
        /* font-family: 'Playfair Display', serif; */
        font-family: 'Roboto, sans-serif';
        font-family: var(--bs-body-font-family)
}

select {
        /* Remove the default appearance */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

}

/* HomePage.css */

.home-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
        text-align: center;
}

.slogan {
        font-size: 2.7rem;
        font-weight: 400;
        margin-bottom: 4.5rem;
        color: white;
        font-style: italic;
        letter-spacing: 0.04cm;
        font-style: italic;
        color: #FF6F61;

}

.button-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 7.3rem;
        margin-bottom: 1.5rem;
}

.inventory-button {
        display: flex;
        flex-direction: column;
        align-items: center;
}

.button-title {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
        color: white;
}

.browse-button {
        background-color: #562F4A;
        color: white;
        padding: 0.5rem 1rem;
        border: none;
        font-size: 1.2rem;

        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
}

.inventory-button:hover .browse-button {
        background-image: linear-gradient(45deg, transparent 50%, #FF6F61 50%);
}

.inventory-button:hover .browse-button {
        background-color: #FF6F61;
        color: white;
}

a {
        text-decoration: none;
}

.gogo {
        margin-top: 3%;
        width: 100%;
        max-width: 41%;
}

.content-wrapper {
        padding: 2rem;
        border-radius: 10px;
}

.content p {
        font-size: 1.1rem;

}

.content {
        margin-bottom: 1.5rem;
}

h2 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
        color: white;
}

p {
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
        color: white;
}

.contact-button {
        padding: 0.6rem 1.4rem;
        background-color: #562F4A;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1rem;
        transition: background-color 0.3s ease;
}

.contact-button:hover {
        background-color: #FF6F61;
}



/* Slider.css */

.slider-container {
        width: 100%;
        height: 220px;
        overflow: hidden;
        position: relative;
}

.slide {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.5s ease;
}

.slide.active {
        opacity: 1;
}

.content-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
}

.contact-info {
        background-color: #f9f9f9;
        padding: 1.8rem;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        text-align: left;
        font-family: 'Roboto, sans-serif';
        font-size: 17.5px;
        color: #562F4A;
        width: 100%;
        max-width: 26%;
        margin: 8%;
        padding-bottom: 1rem;
        padding-top: 1rem;
}

.contact-info h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 19px;
        color: #562F4A;
}

.contact-item {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
}

.contact-icon {
        margin-right: 5px;
        color: #562F4A;
}
 .contact-item .addresss  {
         font-size: 14px;
 }

.contact-info h3 {
        font-size: 15px;
        font-weight: 700;
        margin: 0;
        color: #562F4A;
}

.contact-info p {
        margin: 10px 5px;
        color: #666;
        font-weight: bolder;
        font-size: 15px;
}

@media (max-width: 1108px) {
        .contact-info {
                max-width: 35%;
        }
}

/* Mobile Responsive */
@media (max-width: 1268px) {
        .contact-info {
                max-width: 39%;
        }

}

@media (max-width: 1468px) {
        .gogo {
                max-width: 85%;
        }

        .contact-info {
                max-width: 33%;
        }
}

@media (max-width: 1200px) {
        .contact-info {
                max-width: 38%;
        }
}

@media (max-width: 993px) {
        .contact-info {
                max-width: 45%;
        }
}

@media (max-width: 858px) {
        .contact-info {
                max-width: 51%;
        }
}

@media (max-width: 760px) {
        .contact-info {
                max-width: 57%;
        }
}

@media (max-width: 660px) {
        .contact-info {
                max-width: 68%;
        }
}

/* Add additional styling as desired */

/* Mobile Responsive */
@media (max-width: 768px) {
        .gogo {
                max-width: 100%;
        }
}

/* Mobile Responsive */
@media (max-width: 768px) {
        .slider-container {
                height: 150px;
        }

        .slogan {
                font-size: 2.04rem;
                margin-bottom: 2.3rem;
                margin-top: -2.5%;
        }

        .button-title {
                font-size: 1.6rem;
        }

        .browse-button {
                padding: 0.3rem 0.7rem;
                font-size: 1.1rem;
        }

        .button-container {
                gap: 3.3rem;
        }
}

@media (max-width: 550px) {
        .contact-info {
                max-width: 73.5%;
        }

        .contact-info {
                padding: 1rem;
                padding-left: 1rem;
                padding-right: 1rem;
                font-size: 15px;
        }

}


@media (max-width: 445px) {
        .contact-info {
                max-width: 100%;
        }
                 .contact-item .addresss {
                         font-size: 13px;
                 }

        
}


@media screen and (min-width: 1900px) {
        .contact-item h3 {
                font-size: 1.2rem;
        }

        .contact-info p {
                font-size: 1.3rem;
                margin-top: 3%;
        }

        .contact-info h2 {
                font-size: 1.7rem;
        }

        .browse-button {
                padding: 0.4rem 0.8rem;
                font-size: 1.4rem;
        }

        .button-title {
                font-size: 2.2rem;
        }
        

        .contact-button {
                padding: 0.75rem 1.5rem;

                font-size: 1.2rem;
        }

        .content p {
                font-size: 1.3rem;
        }

        .content h2 {
                font-size: 1.9rem;
        }
       
               
}

@media (max-width: 375px) {
        .contact-info {
                max-width: 98%;
        }

}
.button-containerr {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin-bottom: 0.5%;
}

#woww {
    color: white;

}

.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.results-not-found {
    width: 19.5%;
    padding: 0.9rem;
    font-size: small;
    font-weight: 600;
    background-color: rgb(224, 183, 183);
    color: rgb(228, 27, 27);
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 4%;
}

@media (min-width: 600px) {
    .results-not-found {
        max-width: 40.5%;
        padding: 0.9rem;
        font-size: 0.8rem;
        font-weight: 600;
        background-color: rgb(224, 183, 183);
        color: rgb(228, 27, 27);
        margin-left: 50%;
        transform: translateX(-50%);
        margin-top: 4%;
    }
}

@media (min-width: 890px) {
    .results-not-found {
        max-width: 40.5%;
        padding: 0.9rem;
        font-size: 0.8rem;
        font-weight: 600;
        background-color: rgb(224, 183, 183);
        color: rgb(228, 27, 27);
        margin-left: 50%;
        transform: translateX(-50%);
        margin-top: 4%;
    }
}

@media (min-width: 1160px) {
    .results-not-found {
        max-width: 40.5%;
        padding: 0.9rem;
        font-size: 0.8rem;
        font-weight: 600;
        background-color: rgb(224, 183, 183);
        color: rgb(228, 27, 27);
        margin-left: 50%;
        transform: translateX(-50%);
        margin-top: 4%;
    }
}
.results-not-found {
    width: auto;
    display: inline-block;
    padding: 0.9rem;
    font-size: 0.8rem;
    font-weight: 600;
    background-color: rgb(224, 183, 183);
    color: rgb(228, 27, 27);
    white-space: nowrap;
    border: 2px solid rgb(228, 27, 27);
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 4%;
}

@media (max-width: 890px) {
    .results-not-found {
        font-size: 0.8rem;
    }
}

@media (max-width: 690px) {
    .results-not-found {
        font-size: 0.7rem;
        margin-top: 5%;
        margin-bottom: 10%;
    }
}
@media (max-width: 1500px) {
    .results-not-found {
        margin-top: 15%;
        margin-bottom: 25%;
    }
}
.filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72%;
    padding: 1rem;
    background-color: #562F4A;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 0.2rem;
    margin-top: 4%;
    /* Add this line to create space below the button */
}

.responsive-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.7rem;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 1%;
}

.pagename h3 {
    color: white;
    font-size: 2rem;

}

.pagename {
    margin-top: 2%;
    margin-bottom: -2%;
}

.responsive-container .left-container {
    display: flex;
    align-items: center;
}

.responsive-container .left-container button {
    margin-right: 1rem;
}

.responsive-container .right-container {
    display: flex;
    justify-content: flex-end;
    width: 80%;
}

.responsive-container .right-container label {
    margin-right: 10px;
    align-self: center;
}

.responsive-container .right-container select {
    width: 100%;
    padding: 5px;
}

.filter-button:hover {
    background-color: #FF6F61;
}

.filter-button i {
    margin-right: 0.5rem;

}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(212, 178, 178, 0.1);
}

.dropdown-item {
    margin-bottom: 1rem;
}

.dropdown-item label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #562F4A;
}

.dropdown-item input[type="text"],
.dropdown-item select {
    width: 100%;
    padding: 0.5rem;
    border-radius: 3px;
    font-size: 1rem;
}


.dropdown-item select {
    appearance: none;

    border-radius: 3px;
    padding: 0.5rem;
    font-size: 1rem;
}

.checkbox-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkbox-container label {
    margin-bottom: 0.5rem;

}

.checkbox-container label input[type="checkbox"] {
    margin-right: 0.5rem;
}

.dropdown-menu {
    display: block;
    margin-top: -7%;
    max-width: 70%;
    /* Add this line */
    /* Your existing styles for dropdown-menu */
}

.button-containerr {
    position: relative;
}

.filter-button {
    border: none;
    padding: 10px 20px;
    font-size: 17px;
    cursor: pointer;
}

.filter-icon {
    margin-right: 5px;
}

.message-icon {
    margin-right: 4px;
    margin-bottom: 2px;
    font-size: large;
}

.dropdown-menu {
    position: relative;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: black;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.dropdown-item {
    margin-bottom: 10px;
}

#woww {
    display: block;
    margin-bottom: 5px;
}

input[type='text'],
select {
    width: 100%;
    padding: 5px;
    font-size: 14px;
    border-radius: 4px;
}

/* Remove default styles for dropdown links */
.dropdown-menu a {
    color: inherit;
    text-decoration: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    display: inline-block;
    transition: none;
}

.dropdown-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.dropdown-item {
    flex-basis: calc(30% - 10px);
    margin-bottom: 10px;
}

.dropdown-item label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.dropdown-item select,
.dropdown-item input[type="text"] {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: none;
    font-size: 14px;
}

.dropdown-item select {
    background-color: #f8f8f8;
}

.dropdown-item input[type="text"] {
    background-color: #ffffff;
}

.left-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    padding: -1rem;
    padding-bottom: 1rem;

}

.left-container button {
    background-color: #562F4A;
    color: white;
    padding: 4px;
    border: none;
    border-radius: 10%;
    cursor: pointer;
    font-size: 22px;
    transition: background-color 0.3s ease;
    padding-bottom: 9px;
}

.left-container button:disabled {
    background-color: #562f4a32;
    color: rgba(255, 255, 255, 0.577);
    cursor: not-allowed;
}


.left-container #sas {
    margin: 0 10px;
    font-size: 0.94rem;
    color: white;
    display: inline-block;
    white-space: nowrap;
    margin-right: 1rem;
    margin-left: -0.17rem;
}

.colledata {
    margin-top: 7%;
    max-width: 90%;
    font-size: 1.9rem;
    margin-bottom: 5%;
}

/* Add this to your existing styles */
.reset-buttons-container {
    display: flex;
    gap: 8px;
    overflow: hidden;

    /* Adjust the value to control the gap between buttons */
    margin-top: 12px;
    margin-left: 14rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 90%;
    /* Add some top margin to the button container if needed */
}

.reset-button {
    font-size: 0.9rem;
    background-color: #562F4A;
    color: white;
    border: #562F4A;
    padding: 4px 8px;

    transition: background-color 0.1s ease;
}

.reset-button:hover {
    background-color: #FF6F61;
}
@media (max-width: 1968px) {
.reset-buttons-container {
        /* Arrange buttons vertically */
        gap: 9px;
        margin-left: 15%;
        /* Reduce the gap between buttons for smaller screens */
    }
}

@media (max-width: 1398px) {
    .reset-buttons-container {
        /* Arrange buttons vertically */
        gap: 9px;
        margin-left: 15%;
        /* Reduce the gap between buttons for smaller screens */
    }

}

@media (max-width: 768px) {
    .reset-buttons-container {
        /* Arrange buttons vertically */
        gap: 8px;
        margin-left: 5%;
        /* Reduce the gap between buttons for smaller screens */
    }
}

@media (max-width: 500px) {
    .reset-buttons-container {
        /* Stack buttons vertically */
        align-items: center;
    }

    .reset-button {
        max-width: 100%;
        /* Make buttons take up full width */
    }
}

/* CSS for even smaller screens, e.g., mobiles */
@media (max-width: 480px) {
    .reset-button {
        font-size: 0.9rem;
        gap: 9px;

        /* Further reduce the font size for smaller screens */
    }

    .reset-buttons-container {
        margin-left: 5%;
        gap: 7px;

    }
}

.cards-container {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.card {
    width: 23%;
    background-color: #030303;
    box-shadow: 0 5px 6px #562F4A;
    overflow: hidden;
    position: relative;
    /* Add this line */
}

.card-image {
    width: 56%;
    height: 9.2rem;
    object-fit: cover;
    float: left;
}

.card-content {
    overflow: hidden;
    padding: 22px 2px
}

.card-title {
    margin-bottom: 10px;
    position: absolute;
    top: 3px;
    right: 14px;
    font-size: 1.34rem;
    width: 40%;
    padding-left: 2%;
    color: rgba(204, 204, 204, 0.812);
    white-space: normal;
    /* Update this line */
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
}

.prico {
    position: absolute;
    top: 105px;
    right: 16px;
    font-size: 1.1rem;
    width: 40%;
    padding-left: 2.5%;
    color: rgba(204, 204, 204, 0.812);
    white-space: normal;
    /* Update this line */
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
}

.amountt {
    margin-bottom: 80px;
    position: absolute;
    top: 122px;
    right: 16px;
    font-size: 1.33rem;
    width: 40%;
    padding-left: 2.5%;
    color: rgba(204, 204, 204, 0.812);
    white-space: normal;
    /* Update this line */
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;

}

.card-buttons {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.card-button {
    width: 100%;
    padding: 6px 10px;
    border: none;

    background-color: #562F4A;
    color: #fff;

    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.card-button.detailss {
    background-color: #030303;
    transition: all 0.3s ease;
}

.card-button:hover {
    background-color: #FF6F61;
}

select {
    -webkit-appearance: none;
    /* Remove default iOS styling */
    -moz-appearance: none;
    /* Remove default Firefox styling */
    appearance: none;
    /* Remove default styling */
    color: black;
    /* Inherit the color from the parent element */
}

.contact-form-card .aaaak label {
    margin-bottom: -2%;
    font-size: medium;
}

.contact-form-card .aaaak input {
    padding: 2%;
    font-size: 1.0rem;
}

.carca {
    color: #562F4A;
    margin-top: -2%;
    margin-bottom: 2%;
    font-size: 1.3rem;
    font-weight: 600;
}


.textmenow {
    font-size: 1.5rem;
}

option {
    color: black;
    /* Set the font color for options */
}

.card-button.textme {
    margin-left: -1%;
}


@media (max-width: 1200px) {
    .cards-container {
        flex-wrap: wrap;
    }

    .card {
        width: 28%;
    }

    .card-title {
        font-size: 1.4rem;
    }
}

@media (max-width: 1100px) {
    .cards-container {
        flex-wrap: wrap;
    }

    .card {
        width: 33%;
    }

    .card-title {
        font-size: 1.4rem;
    }
}

@media (max-width: 1000px) {
    .card-button {
        font-size: 1.0rem;
    }
}

@media (max-width: 900px) {
    .cards-container {
        flex-wrap: wrap;
    }

    .card {
        width: 40%;
    }

    .card-title {
        font-size: 1.4rem;
    }
}

@media (max-width: 800px) {

    .card-button {
        font-size: 1.0rem;
    }

    .card-title {
        font-size: 1.4rem;
    }
}

@media screen and (min-width: 1900px) {
    .filter-button {
        margin-bottom: 2.6rem;
        padding: 0.5rem;
        font-size: 1.1rem;
    }

    .card-title {
        font-size: 1.4rem;
    }

    .card-button {
        font-size: 1.2rem;
    }
}

@media (max-width: 700px) {
    .cards-container {
        flex-wrap: wrap;
    }

    .card {
        width: 67%;
        height: 70%;
    }

    .card-title {
        font-size: 1.4rem;
        padding-left: 2%;
    }

    .card-button {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .card {
        width: 94%;
    }

    .card-title {
        font-size: 1.4rem;
    }

    .card-image {
        width: 56%;
    }

    .card-button {
        font-size: 0.9rem;
    }

    .colledata {
        margin-top: 8%;
        font-size: 1.4rem;
        margin-bottom: 7%;
    }
}


@media (max-width: 400px) {
    .card {
        width: 94%;
    }

    .card-title {
        font-size: 1.3rem;
    }

    .card-image {
        width: 56%;
    }
}

/* Add the new media query for 729px with a more specific class selector */

@media (min-width: 729px) {
    .specific-dropdown-menu {
        margin-top: -7.5% !important;
    }

}

@media (min-width: 981px) and (max-width: 1160px) {
    .dropdown-menu {
        margin-top: -10.4% !important;
    }
}

@media (min-width: 738px) and (max-width: 779px) {
    .dropdown-menu {
        margin-top: -7.4% !important;
    }
}

@media (min-width: 769px) and (max-width: 981px) {
    .dropdown-menu {
        margin-top: -12.4% !important;
    }
}

@media (min-width: 738px) and (max-width: 769px) {
    .dropdown-menu {
        margin-top: -13.4%;
    }
}


@media (max-width: 1167px) {
    .dropdown-item {
        flex-basis: 50%;
    }
}

@media (max-width: 767px) {
    .dropdown-item {
        flex-basis: 50%;
    }
}

@media (max-width: 575px) {
    .dropdown-item {
        flex-basis: 100%;
    }
}

/* Remove background color on dropdown link hover and active states */
.dropdown-menu {
    --bs-dropdown-link-hover-bg: transparent !important;
    --bs-dropdown-link-active-bg: transparent !important;
}

/* Media queries for mobile and tablet responsiveness */
@media (max-width: 720px) {
    .responsive-container {
        flex-direction: column;
        margin-left: 5%;
        margin-right: 5%;
    }

    .responsive-container .right-container {
        width: 100%;
    }
}

/* styles.css */

@media (max-width: 720px) {
    .responsive-container .left-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        margin-top: 2rem;
    }

    .responsive-container .right-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 1rem;
    }
}

@media (max-width: 768px) {
    .dropdown-menu {
        position: static;
        box-shadow: none;
        padding: 0;
        width: 80%;
    }
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .filter-button {
        width: 90%;
    }

    .dropdown-menu {
        max-width: 100%;
    }
}

.cards-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* Add flex-wrap property */
    gap: 20px;
}

.card {
    flex: 1 1 calc(30% - 20px);
    /* Adjust width to 30% with a 20px gap between cards */
    max-width: 350px;
    /* Set a maximum width for each card */
    background-color: #030303;
    box-shadow: 0 5px 3px #562F4A;
    overflow: hidden;
    position: relative;
    margin: 10px;
    /* Add some margin between cards */
}

@media (max-width: 1200px) {
    .card {
        flex: 1 1 calc(33.33% - 20px);
        /* Adjust width to 33.33% with a 20px gap between cards */
    }
}

@media (max-width: 900px) {
    .card {
        flex: 1 1 calc(50% - 20px);
        /* Adjust width to 50% with a 20px gap between cards */
    }
}

@media (max-width: 700px) {
    .card {
        flex: 1 1 calc(100% - 20px);
        /* Full width with a 20px gap between cards on small screens */
    }
}
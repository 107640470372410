.mainfoot {
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #5b394f3a;
  margin-top: 7%;

  position: relative;
  bottom: 0;
  margin-top: auto;
  left: 0;
  width: 100%;
  text-align: center;

  margin-top: 10%;

}

.mainfoot a {
  color: inherit;
  text-decoration: none;
  color: white;
  font-size: small;
}

.logojj {
  color: #FF6F61;
  font-style: italic;
  font-size: 0.9rem;
  margin-bottom: 1.4%;
}

mainfoot a:hover {
  color: inherit;
  text-decoration: none;
}

.mainfoot h2 {
  margin-top: 1%;
  font-size: 1.9rem;
  color: #89CFF0;
  font-weight: 600;
  margin-bottom: 0%;
}

.mainfoot p {
  font-size: 0.7rem;
  margin-top: 0.1%;
  color: white;
}

@media screen and (min-width: 1900px) {
  .mainfoot h2 {
    font-size: 2.3rem;
  }
    .logojj {
      
      font-size: 1.2rem;
    }

  .mainfoot p {
    font-size: 1rem;
  }

  .mainfoot a {
    font-size: 1.2rem;
  }
}
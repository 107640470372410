/* privacy.css */

.privacy-container {
    max-width: 85%;
    margin: 0 auto;
    padding: 40px;
    background-color: #130b10;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.privacy-container h1 {
    font-size: 30px;
    margin-bottom: 25px;
    color: white;
    font-weight: bold;
    letter-spacing: 0.03cm;
}

.privacy-container h2 {
    font-size: 16px;
    margin-top: 40px;
    margin-bottom: 16px;
}

.privacy-container p {
    font-size: 14px;
    margin-bottom: 24px;
    line-height: 1.6;
}

/* Mobile and Tablet Responsive */

@media (max-width: 768px) {
    .privacy-container {
        padding: 22px;
    }

    .privacy-container h1 {
        font-size: 24px;
    }

    .privacy-container h2 {
        font-size: 20px;
        margin-top: 32px;
        margin-bottom: 12px;
    }

    .privacy-container p {
        font-size: 14px;
        margin-bottom: 20px;
    }
}
.full {
    padding: 1.2rem;
    font-weight: 600;
    background-color: #562F4A;
}

.wogo {
    justify-content: space-between;
    align-items: center;
    display: flex;
}



.subbut:active {
    animation: buttonClickAnimation 0.3s linear;
}


@keyframes buttonClickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.95);
    }

    100% {
        transform: scale(1);
    }
}

.subbut.loading {
  position: relative;
  cursor: not-allowed;
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  overflow: hidden;
}

.subbut.loading:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
  background-size: 50px 50px;
  animation: loading 1.5s linear infinite;
}

@keyframes loading {
  0% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(150%);
  }
}

.logoo {
    color: #fdf2d1;
    font-weight: bold;
    font-size: x-large;
}

.sign {
    font-size: large;
    justify-content: space-evenly;
}

.sign:hover {
    text-decoration: none;
    font-weight: bolder;
}

.navofnav {

    background-color: black;
    padding: 0.7rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

a span {
    text-decoration: none;
}

.w {
    cursor: pointer;
    width: 11rem;
}


.navofnav {
    background-color: black;
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.navbar-text {
    font-size: 1.9rem;
    font-weight: 600;
    color: white;
}

.navbar-buttons {
    display: flex;
    align-items: center;
}

.navbar-button {
    background-color: #562F4A;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    margin-left: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.navbar-icon {
    /* margin-right: 0.5rem; */
    padding-bottom: 0.1rem;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.navbar-text {
    font-size: 1.3rem;
}

.navbar-buttons {
    display: flex;
}

.navbar-button {
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}


.custom-toast .react-toast-notifications__toast--success {
    background-color: #562F4A;
    /* S
    et the desired background color */
}

.css-3mfg68-Icon {
    background-color: #562F4A;
}

.react-toast-notifications__toast__countdown {
    background-color: #562F4A;
}
.react-toast-notifications__toast__icon-wrapper{
    background-color: #562F4A;
}
.react-toast-notifications__toast__icon-wrapper .css-3mfg68-Icon{
    background-color: #562F4A;
}
.custom-toast .react-toast-notifications__toast--success .react-toast-notifications__toast__message {
    color: white;
    font-size: 13.5px;
    /* Set the desired text color */
}

.navbar-text {
    font-size: 2.42rem;
    color: #FF6F61;
    /* color: #db364f; */
}

.navbar-buttons {
    display: flex;
}

.navbar-button {
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
}

.contact-form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.contact-form-card {
    background-color: #f9f9f9;
    padding: 2rem;
    border-radius: 8px;
    width: 35%;
    max-width: 400px;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.1rem;
    background-color: transparent;
    border: none;
    color: #333;
    font-size: 1.0rem;
    cursor: pointer;
}

.contact-form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact-form-card input:focus,
.contact-form-card textarea:focus {
    outline: none;
}

.contact-form-header h2 {
    margin: 0;
    color: #562f4a;
    font-weight: 600;
}

.contact-form-section {
    margin-bottom: 0.3rem;
}

.contact-form-card label {
    font-weight: 600;
    color: #562F4A;
}

.contact-form-card label::after {
    color: #e32;
    content: ' *';
    display: inline;
}

.consent-container {
    display: flex;
    align-items: center;
}

.no-scroll {
    overflow: hidden;
}

.consent-container input[type="checkbox"] {
    margin-right: 0px;
    width: 10%;
    font-size: small;
}

.consent-label {
    margin-top: 1.5%;
}

.contact-form-card input,
.contact-form-card textarea {
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.consent-label {
    font-size: 0.79rem;
}

.contact-form-card textarea {
    resize: none;
}

.contact-form-card button[type='submit'] {
    margin-top: 3%;
    padding: 0.5rem 1rem;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.contact-form-card button[type='submit']:hover {
    background-color: #FF6F61;

}

.contact-form-card {
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 8px;
    max-width: 400px;
    width: 90%;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.contact-form-card h2 {
    margin-bottom: 1rem;
    color: #562F4A;
    font-weight: 600;

}

.contact-form-card form {
    display: flex;
    flex-direction: column;
}

.contact-form-card label {
    margin-bottom: 0.3rem;
}

.contact-form-card input,
.contact-form-card textarea {
    padding: 0.5rem;
    margin-bottom: 0.1rem;
}

.contact-form-card button[type='submit'] {
    padding: 0.5rem 1rem;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}


.contact-form-card button[type='submit']:hover {
    background-color: #FF6F61;

}

.consent-text p {
    font-size: smaller;
    color: #6d3f5f;

}

.consent-text {
    text-align: left;
    overflow-y: auto;
    max-height: 6rem;
}

.close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.2rem;
    background-color: transparent;
    border: none;
    color: #562F4A;
    font-size: 1.4rem;
    cursor: pointer;
}

.close-button:hover {
    color: #555;
}
.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.navbar-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: inherit;
}

.navbar-text {
    color: #89CFF0;
    /* #89CFF0 */
}

.navbar-slogan {
    font-style: italic;
    font-size: 1.2rem;
    color: #FF6F61;
    margin-top: -1rem;
    margin-bottom: 0.6rem;
}
.contact-form-card {
    background-color: #f9f9f9;
    padding: 1.5rem;
    border-radius: 8px;
    max-width: 400px;
    width: 90%;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    /* Enable vertical scrolling */
    max-height: 93vh;
    /* Limit the maximum height of the form */
}


@media screen and (max-width: 567px) {
    .contact-form-card {
        max-width: 100%;
        width: 90%;
        max-height: 90vh;
        margin-top: -2%;
        /* Adjust the maximum height as needed */
    }
}

@media screen and (max-width: 567px) {
    .contact-form-card {
        max-width: 100%;
        width: 85%;
        margin-top: 5%;
    }
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .navofnav {
        flex-direction: column;
        align-items: center;
    }

    .navbar-container {
        flex-direction: column;
        align-items: center;
    }

    .navbar-text {
        text-align: center;
        margin-bottom: 0.1rem;
    }

    .navbar-buttons {
        flex-direction: row;
    }

    .navbar-button {
        margin-left: 0.5rem;
        font-size: 0.8rem;
    }
}

@media (max-width: 768px) {
    .navbar-buttons {
        margin-top: 0.6rem;
    }

}
@media screen and (min-width: 1900px) {
    .navbar-text {
            font-size: 2.6rem;
          
        }
        .navbar-button{
            padding: 0.5rem 1rem;
                font-size: 1.2rem;
        }
}
/* Reset some default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Global styles */

/* Form styles */
.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4.9%;
}

input:focus {
    outline: none;
}

.x-label::after {
    color: #e32;
    content: ' *';
    display: inline;
}

.card-form {
    width: 64%;
    padding: 2rem;
    background-color: #5b394f3a;
    border-radius: 8px;
    box-shadow: 0 5px 7px #5b738ca8;
    margin-bottom: 2%;
}

.form-title {
    text-align: left;
    margin-bottom: 2rem;
    letter-spacing: 0.02cm;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: white;
}

.form-row {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.7rem;

}

.form-group {
    width: 48%;
}


.form-label {
    display: block;
    margin-bottom: 0.32rem;
    font-size: 1.1rem;
    color: white;
}

.form-input {
    width: 100%;
    padding: 4px;
    padding-left: 8px;
    font-size: medium;
    color: #333;
    transition: border-color 0.3s ease;
}

input[type="text"].form-input {
    padding: 4px;
    padding-left: 8px;
    font-size: medium;
}

.submit-button {
    display: block;
    width: 14%;
    margin: 0 auto;
    padding: 0.4rem;
    background-color: white;
    color: #562F4A;
    border: none;
    border-radius: 4px;
    font-size: 1.0rem;
    font-weight: 600;
    margin-top: 2%;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #562F4A;
    color: white;
}

.image-row {
    display: flex;
    flex-wrap: wrap;
}

.image-column {
    width: 50%;
    padding: 0.5rem;
    box-sizing: border-box;
}

.image-column img {
    width: 100%;
    height: auto;
    max-height: 28rem;
    max-width: 43rem;
}

.custom-toast {
    font-size: 10px;
}

.css-18gu508-ContentP {
    font-size: 10px;
}

.react-toast-notifications__toast__content .css-18gu508-Content {
    font-size: 5px;
}

.form-textarea {
    resize: none;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;

}

.form-textarea:focus {
    outline: none;
}

.form-select:focus {
    outline: none;
    box-shadow: none
}

.image-column {
    display: flex;
    justify-content: center;
}

.image-container {
    position: relative;

    overflow: hidden;
}

.image {

    transition: filter 0.3s ease;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Adjust the background color and opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    /* Add transform transition */
    transform: scale(0.8);
    /* Initial scale of overlay */
}

.image-container:hover .image-overlay {
    opacity: 1;
    transform: scale(1);
    /* Scale overlay to 100% on hover */
}

.classictitle {
    font-style: italic;
    font-size: 2.2rem;
    margin-top: 2%;
    margin-bottom: 2%;
}

.center {
    text-align: center;
    margin-top: 2%;
}

.back-to-form {
    padding: 4px 14px;
    border: none;
    background-color: #562F4A;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.back-to-form .arrow {
    font-size: 20px;
}

.back-to-form:hover {
    background-color: white;
    color: #562F4A;
}

.image-text {
    color: white;
    /* Adjust the text color */
    font-size: 24px;
    /* Adjust the font size */
    font-weight: bold;
    transform: translateY(20px);
    /* Initial position of text */
    transition: transform 0.3s ease;
    /* Add transform transition */
}

.image-container:hover .image-text {
    transform: translateY(0);
    /* Move text upward on hover */
}

.cola {
    width: 32%;
}

.coki {
    width: 100%;
}

.submit-button.loadingi {
    position: relative;
    cursor: not-allowed;
    background-color: transparent;
    color: white;
    border: 2px solid #fff;
    overflow: hidden;
}

.submit-button.loadingi:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
    background-size: 50px 50px;
    animation: loadingAnimation 1.5s linear infinite;
}

@keyframes loadingAnimation {
    0% {
        transform: translateX(-150%);
    }

    100% {
        transform: translateX(150%);
    }
}

.subbut:active {
    animation: buttonClickAnimation 0.3s linear;
}

@keyframes buttonClickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.95);
    }

    100% {
        transform: scale(1);
    }
}


@media (max-width: 600px) {
    .image-column {
        width: 100%;
    }
    .back-to-form{
        font-size: 1.0rem;
    }

    .classictitle {
        font-style: italic;
        font-size: 1.7rem;
        margin-top: 5%;
    }

    .submit-button.loadingi {
        font-size: 0.9rem;
    }

    .image-text {
        font-size: 18px;
    }
    .pricetagi{
        font-size: 16px;
    }

    .cola {
        width: 98%;
        margin-bottom: 10%;
    }
}

@media screen and (min-width: 1500px) {
    .image-column img {
        width: 100%;
        height: auto;
        max-height: 28.0rem;
    }

    .back-to-form {
        font-size: 1.2rem;
    }

    .classictitle {
        font-size: 2.2rem;
    }

    .form-title {
        font-size: 1.5rem;
    }

}

/* Responsive styles 
  
*/
@media screen and (max-width: 1367px) {
    .submit-button {
        width: 20%;
        margin-top: 5%;
        font-size: 1.0rem;
    }
}

@media screen and (max-width: 767px) {

    /* Styles for mobile devices */
    .card-form {
        width: 90%;
        padding: 1rem;
    }

    .form-row {
        flex-direction: column;
    }

    .form-group {
        width: 100%;
        margin-bottom: 2%;
    }

    .cola {
        margin-bottom: 4%;
    }

    .submit-button {
        width: 35%;
        margin-top: 5%;
        font-size: 0.98rem;
    }
    .back-to-form{
        font-size: 1.1rem;
    }

    .submit-button.loadingi {
        width: 32%;
        margin-top: 5%;
        font-size: 0.7rem;
    }

    .form-row {
        margin-bottom: 0rem;
    }

    .form-title {
        font-size: 1.3rem
    }
}

.consent-containerr {
    display: flex;
    align-items: center;
    margin-top: 4%;
}

.consent-label.lololi {
    /* margin-left: 10px; */
    font-size: .9rem;
    color: white;
    margin-top: 0.2%;
    text-transform: uppercase;
}

.consent-containerr input[type="checkbox"] {
    margin-right: 10px;
}

.consent-containerr,
.textind {
    margin-bottom: 1px;
    font-size: medium;
}

.textind {
    max-height: 6rem;
    overflow-y: auto;
}

.desired {
    margin-top: 2.4%;
    font-weight: 650;
    font-size: medium;
    margin-bottom: 1.0%;
}


@media screen and (min-width: 768px) and (max-width: 1023px) {

    /* Styles for tablet devices */
    .card-form {
        width: 80%;
    }

    .form-row {
        margin-bottom: 0.7rem;
    }

}

@media screen and (min-width: 1900px) {
    .submit-button {
        padding: 0.6rem;
        font-size: 1.1rem;
    }

    .consent-label.lololi {
        /* margin-left: 10px; */
        font-size: 1.1rem;
    }

    .form-label {
        font-size: 1.4rem;
    }

    .form-input {
        padding: 5px;
        padding-left: 8px;
        font-size: large;
    }

    input[type="text"].form-input {
        padding: 5px;
        padding-left: 8px;
        font-size: large;
    }

}
/* UnderConstruction.css */
.under-construction-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: black;
    /* font-family: Arial, sans-serif; */
    font-family: 'Playfair Display', serif;
    color: #fff;
    padding: 2rem;
}

.title {
    font-size: 5rem;
    margin-bottom: 2rem;
    text-shadow: 2px 2px 4px #562f4a;
    text-align: center;
}

.construction-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24rem;
}

.construction-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    font-size: 4rem;
    color: #89CFF0;
    background-color: #562f4a;
    border-radius: 50%;
    box-shadow: 0 0 20px #562f4a;
    animation: float 3s infinite ease-in-out;
    transition: transform 0.3s, background-color 0.3s;
}

.construction-icon:hover {
    transform: translateY(-10px);
    background-color: #FF6F61;
}

.rotating {
    animation: rotate 3s infinite linear;
}

@keyframes float {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.message {
    font-size: 3rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.fancy-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #ff9800, #f44336, #9c27b0, #2196f3);
    background-size: 800% 800%;
    animation: gradient-animation 8s ease infinite;
    z-index: -1;
}

@keyframes gradient-animation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

/* Responsive Styling */
@media screen and (max-height: 445px) {
    .title {
        margin-top: 2rem;
        font-size: 3rem;
    }

    .construction-icon {
        width: 65px;
        height: 65px;
        font-size: 3rem;
    }

    .message {
        font-size: 1.9rem;
    }

}

@media screen and (max-width: 768px) {
    .title {
        font-size: 4rem;
    }

    .construction-icon {
        width: 100px;
        height: 100px;
        font-size: 4rem;
    }

    .message {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 480px) {
    .title {
        font-size: 3.5rem;
    }

    .construction-icon {
        width: 70px;
        height: 70px;
        font-size: 3rem;
    }

    .message {
        font-size: 2rem;
    }
}